import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ReactGA from 'react-ga4';
import posthog from 'posthog-js';

ReactGA.initialize('G-HZR7Z36C70');
ReactGA.send('pageview');

posthog.init('phc_rBY3o6I07gnNY8h6Z6zKVVSwrvec1nbellibIsBPjgp', {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

import { initializeApp } from 'firebase/app';
import 'firebase/firestore'
import { getFirestore } from 'firebase/firestore';
import 'firebase/functions'


const firebaseConfig = {
  apiKey: "AIzaSyDsiYdJ5X2DEFb158-e01wyoEPVKekUFG8",
  authDomain: "gisela-web.firebaseapp.com",
  projectId: "gisela-web",
  storageBucket: "gisela-web.appspot.com",
  messagingSenderId: "978125067882",
  appId: "1:978125067882:web:904c5700541d328b60dc13"
};

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  export {
    db,
    app
  }
import React from 'react'
import ContactForm from '../../components/contactForm'
import H1 from '../../components/atoms/H1'

function Kontakt() {
  return (
    <div className='container'>
        <H1>Kontakt</H1>
        <ContactForm /> 
        
        <p style={{textAlign: 'center'}}>whatsapp: 0157 74457917<br /><br />
Büro: 0351 8020066</p>
    </div>
  )
}

export default Kontakt
import { useParams } from 'react-router-dom'
import H2 from '../../components/atoms/H2';
import H1 from '../../components/atoms/H1';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Logo from '../../img/logo.png'

function Event({events}) {
    const params = useParams();
    const navigate = useNavigate();
    const [thisEvent, setThisEvent] = useState(null)

    useEffect(() => {
      if(params.eventid && events.length > 0) {
        setThisEvent(events.filter(e => e.id == params.eventid)[0])
      }
    }, [events, params])

    
    var date =  thisEvent && new Date(thisEvent.start_time);

  return thisEvent && (
    <>
      <div className='event-wrapper' onClick={() => navigate('/event/'+thisEvent.id)}>
        <H1>{thisEvent.name}</H1>
          <img class="cover mt-2" src={thisEvent.cover.source} />
          <p className='event-starttime mt-1 mb-2'>
            {date.toLocaleString('de-DE', {weekday: 'short', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'})} Uhr
          </p>
          {thisEvent.ticket_uri && <a className="ticket-link" href={thisEvent.ticket_uri} target='_blank'>Zu den VVK-Tickets</a>}
          <p className="event-description">
            {thisEvent.description}
            <br />
          <br />
          <a className='more-infos-cta' href={'https://www.facebook.com/events/'+thisEvent.id} target="_blank">Mehr Infos</a>
          </p>
        </div>
    </>
  )
}

export default Event
import React from 'react'
import H1 from '../../components/atoms/H1'
import './index.css'

function FAQ() {
  return (
    <div className='container'>
    <H1>FAQ - Häufige Fragen</H1>
    <div className='faq-wrapper'>
        <div className='single-faq-wrapper'>
            <h1 className='faq-title'>
            An welchen Tagen hat der Club geöffnet?
            </h1>
            <p className='faq-anwer'>
            Unser regulärer Öffnungstag ist der Donnerstag. Weitere Veranstaltungen findest du in unserem Veranstaltungskalender oder auf unserer Facebook-Seite.
            </p>
        </div>
        <div className='single-faq-wrapper'>
            <h1 className='faq-title'>
            Wie alt muss ich sein?
            </h1>
            <p className='faq-anwer'>
            Der Eintritt bei uns ist ausschließlich ab 18 Jahren gestattet. 
            </p>
        </div>
        <div className='single-faq-wrapper'>
            <h1 className='faq-title'>
            An welchen Tagen hat der Club geöffnet?
            </h1>
            <p className='faq-anwer'>
            Unser regulärer Öffnungstag ist der Donnerstag. Weitere Veranstaltungen findest du in unserem Veranstaltungskalender oder auf unserer Facebook-Seite.
            </p>
        </div>
        <div className='single-faq-wrapper'>
            <h1 className='faq-title'>
            Was muss ich beim Einlass beachten?
            </h1>
            <p className='faq-anwer'>
            Bitte halte für die Alterskontrolle deinen Personalausweis oder Reisepass bereit.
Möchtest du evtl. einen Studentenrabatt in Anspruch nehmen, musst du deinen !gültigen! Studentenausweis vorzeigen.
            </p>
        </div>
        <div className='single-faq-wrapper'>
            <h1 className='faq-title'>
            Hilft mir ein Muttizettel?
            </h1>
            <p className='faq-anwer'>
            Wir akzeptieren keine Begleit- oder Aufsichtspersonen und auch keine Muttizettel.  
            </p>
        </div>
        <div className='single-faq-wrapper'>
            <h1 className='faq-title'>
            Was muss ich bei VVK-Tickets beachten?
            </h1>
            <p className='faq-anwer'>
            VVK-Tickets garantieren keinen Einlass in den Club. Insbesondere, wenn gegen die Hausordnung verstoßen wird. Diese hängt am Einlass aus.
            </p>
        </div>

        <div className='single-faq-wrapper'>
            <h1 className='faq-title'>
            Wie sollte ich gekleidet sein?
            </h1>
            <p className='faq-anwer'>
            Bei uns im Club sind folgende Bekleidungsmarken nicht erwünscht:
Thor Steinar / Yakuza / Mafia & Crime / La Familia / Label 23 / Amstaff
Sowie weitere Marken, die nicht in das „typische Erscheinungsbild“ eines Discobesuchers passen, dazu zählen auch Sporthosen, Vereinssachen und „Türsteherhosen“.
            </p>
        </div>

        <div className='single-faq-wrapper'>
            <h1 className='faq-title'>
            Ich habe etwas verloren, bekomme ich das wieder?
            </h1>
            <p className='faq-anwer'>
                Vermisst du etwas, kannst du uns gern kontaktieren. Haben wir etwas gefunden, bewahren wir es auf.
            </p>
        </div>
    </div>
    </div>
  )
}

export default FAQ
import React from 'react'
import './index.css'

function Footer() {
  return (
    <div className='footer'>
        <div className='links'>
            <a href="/impressum">Impressum</a>
        </div>
        <div class="copy-right">Made with ❤️ by <a href="https://wa.me/491755556578" target="_blank">Marius Gebert</a></div>
    </div>
  )
}

export default Footer
import React from 'react'
import H1 from '../../components/atoms/H1'

function Impressum() {
  return (
    <div className='container'>
        <H1>Impressum und Datenschutz</H1>
        <div style={{ width: '100%'}}>
        Firmenanschrift: <br/>
        Blue-Gastro Betrieb GmbH<br/>
        Loschwitzer Straße 3<br/>
        01309 Dresden<br/><br/>


Tel: 0351/8020066<br/>
Fax: 0351/8020067<br/><br/>

e-mail: office@blue-dresden.de<br/><br/>

USt-IdNr.: DE224828693<br/>
Handelsregister Dresden HRB 21165<br/>
vertreten durch Geschäftsführer<br/><br/>

Copyright: alle Inhalte, insbesondere Fotomaterial sind kopierrechtlich geschützt<br/><br/>

Datenschutzerklärung<br/>
Verantwortlicher für die Datenverarbeitung ist:<br/>
BLUE Gastro Betrieb GmbH<br/>
Loschwitzer Straße 3<br/>
01309 Dresden<br/>
office@gisela.club<br/><br/>

Wir freuen uns über Ihr Interesse an unserem Online-Shop. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.<br/><br/>

1. Zugriffsdaten und Hosting<br/>
Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf dokumentiert.Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der Seite sowie der Verbesserung unseres Angebots ausgewertet. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung unseres Angebots. Alle Zugriffsdaten werden spätestens sieben Tage nach Ende Ihres Seitenbesuchs gelöscht.
<br/><br/>

Hostingdienstleistungen durch einen Drittanbieter<br/>
Im Rahmen einer Verarbeitung in unserem Auftrag erbringt ein Drittanbieter für uns die Dienste zum Hosting und zur Darstellung der Webseite. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung unseres Angebots. Alle Daten, die im Rahmen der Nutzung dieser Webseite oder in dafür vorgesehenen Formularen im Onlineshop wie folgend beschrieben erhoben werden, werden auf seinen Servern verarbeitet. Eine Verarbeitung auf anderen Servern findet nur in dem hier erläuterten Rahmen statt.
<br/><br/>

Dieser Dienstleister sitzt innerhalb eines Landes der Europäischen Union oder des Europäischen Wirtschaftsraums.

2. Datenerhebung und -verwendung zur Vertragsabwicklung<br/>
Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen Ihrer Bestellung oder bei einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) mitteilen. Pflichtfelder werden als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur Vertragsabwicklung, bzw. zur Bearbeitung Ihrer Kontaktaufnahme benötigen und Sie ohne deren Angabe die Bestellung nicht abschließen, bzw. die Kontaktaufnahme nicht versenden können. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Wir verwenden die von ihnen mitgeteilten Daten gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen. Nach vollständiger Abwicklung des Vertrages werden Ihre Daten für die weitere Verarbeitung eingeschränkt und nach Ablauf etwaiger steuer- und handelsrechtlichen Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.
<br/><br/>
3. Datenweitergabe<br/>
Zur Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO geben wir Ihre Daten an das mit der Lieferung beauftragte Versandunternehmen weiter, soweit dies zur Lieferung bestellter Waren erforderlich ist. Je nach dem, welchen Zahlungsdienstleister Sie im Bestellprozess auswählen, geben wir zur Abwicklung von Zahlungen die hierfür erhobenen Zahlungsdaten an das mit der Zahlung beauftragte Kreditinstitut und ggf. von uns beauftragte Zahlungsdienstleister weiter bzw. an den ausgewählten Zahlungsdienst. Zum Teil erheben die ausgewählten Zahlungsdienstleister diese Daten auch selbst, soweit Sie dort ein Konto anlegen. In diesem Fall müssen Sie sich im Bestellprozess mit Ihren Zugangsdaten bei dem Zahlungsdienstleister anmelden. Es gilt insoweit die Datenschutzerklärung des jeweiligen Zahlungsdienstleisters.
<br/><br/>
4. Cookies und Webanalyse<br/>
Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, um passende Produkte anzuzeigen oder zur Marktforschung verwenden wir auf verschiedenen Seiten sogenannte Cookies. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimierten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Cookies sind kleine Textdateien, die automatisch auf Ihrem Endgerät gespeichert werden. Einige der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns, Ihren Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies). Die Dauer der Speicherung können Sie der Übersicht in den Cookie-Einstellungen Ihres Webbrowsers entnehmen. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und einzeln über deren Annahme entscheiden oder die Annahme von Cookies für bestimmte Fälle oder generell ausschließen. Jeder Browser unterscheidet sich in der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemenü jedes Browsers beschrieben, welches Ihnen erläutert, wie Sie Ihre Cookie-Einstellungen ändern können. Diese finden Sie für die jeweiligen Browser unter den folgenden Links:
<br/><br/>
Internet Explorer™: https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies<br/>
Safari™: https://support.apple.com/kb/ph21411?locale=de_DE<br/>
Chrome™: https://support.google.com/chrome/answer/95647?hl=de&hlrm=en<br/>
Firefox™ https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen<br/>
Opera™ : https://help.opera.com/de/latest/web-preferences/#cookiesBei der Nichtannahme von Cookies kann die Funktionalität unserer Website eingeschränkt sein.<br/>

<br/><br/>
Einsatz von Google (Universal) Analytics zur Webanalyse
Zur Webseitenanalyse setzt diese Website Google (Universal) Analytics ein, einen Webanalysedienst der Google LLC (www.google.de). Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimierten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Google (Universal) Analytics verwendet Methoden, die eine Analyse der Benutzung der Website durch Sie ermöglichen, wie zum Beispiel Cookies. Die automatisch erhobenen Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Durch die Aktivierung der IP-Anonymisierung auf dieser Webseite wird dabei die IP-Adresse vor der Übermittlung innerhalb der Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte anonymisierte IP-Adresse wird grundsätzlich nicht mit anderen Daten von Google zusammengeführt. Nach Zweckfortfall und Ende des Einsatzes von Google Analytics durch uns werden die in diesem Zusammenhang erhobenen Daten gelöscht.

<br/><br/>
Die Google LLC hat ihren Hauptsitz in den USA und ist zertifiziert unter dem EU-US-Privacy Shield. Ein aktuelles Zertifikat kann hier eingesehen werden. Aufgrund dieses Abkommens zwischen den USA und der Europäischen Kommission hat letztere für unter dem Privacy Shield zertifizierte Unternehmen ein angemessenes Datenschutzniveau festgestellt.

<br/><br/>
Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de

Alternativ zum Browser-Plugin können Sie diesen Link klicken, um die Erfassung durch Google Analytics auf dieser Website zukünftig zu verhindern. Dabei wird ein Opt-Out-Cookie auf Ihrem Endgerät abgelegt. Löschen Sie Ihre Cookies, müssen Sie den Link erneut klicken.

<br/><br/>Google Maps<br/>
Diese Website verwendet Google Maps zur visuellen Darstellung von geographischen Informationen Google Maps wird von Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend: Google) betrieben. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimierten Darstellung unseres Angebots sowie einer leichten Erreichbarkeit unserer Standorte gemäß Art. 6 Abs. 1 lit. f) DSGVO.
Bei der Verwendung von Google Maps werden von Google Daten über die Nutzung der Maps-Funktionen durch Webseitenbesucher übermittelt bzw. verarbeitet, wozu insbesondere die IP-Adresse sowie Standortdaten gehören können. Wir haben keinen Einfluss auf diese Datenverarbeitung.
Google ist zertifiziert unter dem EU-US-Privacy Shield. Ein aktuelles Zertifikat kann hiereingesehen werden. Aufgrund dieses Abkommens zwischen den USA und der Europäischen Kommission hat letztere für unter dem Privacy Shield zertifizierte Unternehmen ein angemessenes Datenschutzniveau festgestellt.
Um den Service von Google Maps zu deaktivieren und damit die Datenübermittlung an Google zu verhindern, müssen Sie die Java-Script-Funktion in Ihrem Browser deaktivieren. In diesem Fall kann Google Maps nicht bzw. nur eingeschränkt genutzt werden.
Weitergehende Informationen über die Datenverarbeitung durch Google finden Sie in den Datenschutzhinweisen von Google. Die Nutzungsbedingungen für Google Maps beinhalten detaillierte Informationen zum Kartendienst.

<br/><br/>Google reCAPTCHA<br/>
Zum Zwecke des Schutzes vor Missbrauch unserer Web-Formulare sowie vor Spam nutzen wir im Rahmen einiger Formulare auf dieser Webseite den Google reCAPTCHA Service des Unternehmens Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend: Google) Durch die Überprüfung einer manuellen Eingabe verhindert dieser Dienst, dass automatisierte Software (sog. Bots) missbräuchliche Aktivitäten auf der Website ausführt. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen am Schutz unserer Webseite vor Missbrauch sowie an einer störungsfreien Darstellung unseres Onlineauftritts.
Google reCAPTCHA verwendet mittels eines in der Webseite eingebundenen Codes, einem sog. JavaScript, im Rahmen der Überprüfung Methoden, die eine Analyse der Benutzung der Website durch Sie ermöglichen, wie zum Beispiel Cookies. Die automatisch erhobenen Informationen über Ihre Benutzung dieser Website einschließlich Ihrer IP Adresse werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Daneben werden andere, durch Google Dienste in Ihrem Browser gespeicherte Cookies durch Google reCAPTCHA ausgewertet.
Ein Auslesen oder Speichern von personenbezogenen Daten aus den Eingabefeldern des jeweiligen Formulars findet nicht statt.
Google ist zertifiziert unter dem EU-US-Privacy Shield. Ein aktuelles Zertifikat kann hiereingesehen werden. Aufgrund dieses Abkommens zwischen den USA und der Europäischen Kommission hat letztere für unter dem Privacy Shield zertifizierte Unternehmen ein angemessenes Datenschutzniveau festgestellt.
Sie können die Erfassung der durch das JavaScript, bzw. das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie in Ihren Browser-Einstellungen die Ausführung von JavaScripten oder das Setzen von Cookies unterbinden. Bitte beachten Sie, dass dies die Funktionalität unseres Webangebots für Ihre Nutzung einschränken kann.
Weitere Informationen zu der Datenschutzpolicy von Google finden Sie hier.

<br/><br/>Google Fonts<br/>
Auf dieser Webseite ist der Script Code „Google Fonts“ des Unternehmens Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend: Google) eingebunden. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer einheitlichen Darstellung der Inhalte auf unserer Webseite gemäß Art. 6 Abs. 1 lit. f) DSGVO.
In diesem Rahmen wird eine Verbindung zwischen dem von Ihnen verwendeten Browser und den Servern von Google hergestellt. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Webseite aufgerufen wurde.
Google ist zertifiziert unter dem EU-US-Privacy Shield. Ein aktuelles Zertifikat kann hiereingesehen werden. Aufgrund dieses Abkommens zwischen den USA und der Europäischen Kommission hat letztere für unter dem Privacy Shield zertifizierte Unternehmen ein angemessenes Datenschutzniveau festgestellt.
Weitergehende Informationen über die Datenverarbeitung durch Google finden Sie in den Datenschutzhinweisen von Google.

<br/><br/>Unsere Onlinepräsenz auf Facebook, Google, Instagram<br/>
Unsere Präsenz auf sozialen Netzwerken und Plattformen dient einer besseren, aktiven Kommunikation mit unseren Kunden und Interessenten. Wir informieren dort über unsere Produkte und laufende Sonderaktionen.
Bei dem Besuch unserer Onlinepräsenzen in sozialen Medien können Ihre Daten für Marktforschungs- und Werbezwecke automatisch erhoben und gespeichert werden. Aus diesen Daten werden unter Verwendung von Pseudonymen sog. Nutzungsprofile erstellt. Diese können verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten, die mutmaßlich Ihren Interessen entsprechen. Zu diesem Zweck werden im Regelfall Cookies auf Ihrem Endgerät eingesetzt. In diesen Cookies werden das Besucherverhalten und die Interessen der Nutzer gespeichert. Dies dient gem. Art. 6 Abs. 1 lit. f. DSGVO der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimierten Darstellung unseres Angebots und effektiver Kommunikation mit den Kunden und Interessenten. Falls Sie von den jeweiligen Social-Media Plattformbetreibern um eine Einwilligung (Einverständnis) in die Datenverarbeitung gebeten werden, z.B. mit Hilfe einer Checkbox, ist die Rechtsgrundlage der Datenverarbeitung Art. 6 Abs. 1 lit. a DSGVO.
Soweit die vorgenannten Social-Media Plattformen ihren Hauptsitz in den USA haben, gilt Folgendes: Für die USA liegt ein Angemessenheitsbeschluss der Europäischen Kommission vor. Dieser geht zurück auf den EU-US Privacy Shield. Ein aktuelles Zertifikat für das jeweilige Unternehmen kann hier eingesehen werden.
Die detaillierten Informationen zur Verarbeitung und Nutzung der Daten durch die Anbieter auf deren Seiten sowie eine Kontaktmöglichkeit und Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre, insbesondere Widerspruchsmöglichkeiten (Opt-Out), entnehmen Sie bitte den unten verlinkten Datenschutzhinweisen der Anbieter. Sollten Sie diesbezüglich dennoch Hilfe benötigen, können Sie sich an uns wenden.
<br/>Facebook: https://www.facebook.com/about/privacy/

<br/><br/>Die Datenverarbeitung erfolgt auf Grundlage einer Vereinbarung zwischen gemeinsam Verantwortlichen gemäß Art. 26 DSGVO, die Sie hier einsehen können:<br/>
https://www.facebook.com/legal/terms/page_controller_addendum

<br/><br/>Google/ YouTube: https://policies.google.com/privacy<br/>
Instagram: https://help.instagram.com/519522125107875

<br/><br/>Widerspruchsmöglichkeit (Opt-Out):<br/>
Facebook: https://www.facebook.com/settings?tab=ads<br/>
Google/ YouTube: https://adssettings.google.com/authenticated<br/>
Instagram: https://help.instagram.com/519522125107875<br/>

<br/><br/>5. Kontaktmöglichkeiten und Ihre Rechte<br/><br/>
Als Betroffener haben Sie folgende Rechte:<br/><br/>

gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen;<br/>
gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;<br/>
gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die weitere Verarbeitung<br/><br/>
– zur Ausübung des Rechts auf freie Meinungsäußerung und Information;<br/>
– zur Erfüllung einer rechtlichen Verpflichtung;<br/>
– aus Gründen des öffentlichen Interesses oder<br/>
– zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen<br/>
erforderlich ist;<br/>
gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit
– die Richtigkeit der Daten von Ihnen bestritten wird;<br/>
– die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;<br/>
– wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder<br/>
– Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;<br/><br/>
gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Einschränkung oder Löschung von Daten sowie Widerruf erteilter Einwilligungen oder Widerspruch gegen eine bestimmte Datenverwendung wenden Sie sich bitte direkt an uns über die Kontaktdaten in unserem Impressum.
<br/><br/>
********************************************************************
<br/><br/>
Widerspruchsrecht<br/>
Soweit wir zur Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen personenbezogene Daten wie oben erläutert verarbeiten, können Sie dieser Verarbeitung mit Wirkung für die Zukunft widersprechen. Erfolgt die Verarbeitung zu Zwecken des Direktmarketings, können Sie dieses Recht jederzeit wie oben beschrieben ausüben. Soweit die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen ein Widerspruchsrecht nur bei Vorliegen von Gründen, die sich aus Ihrer besonderen Situation ergeben, zu.
<br/><br/>
Nach Ausübung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht weiter zu diesen Zwecken verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
<br/><br/>
Dies gilt nicht, wenn die Verarbeitung zu Zwecken des Direktmarketings erfolgt. Dann werden wir Ihre personenbezogenen Daten nicht weiter zu diesem Zweck verarbeiten.<br/><br/>********************************************************************
<br/><br/>
Datenschutzerklärung erstellt mit dem Trusted Shops Rechtstexter in Kooperation mit Wilde Beuger Solmecke Rechtsanwälte.
        </div>
    </div>
  )
}

export default Impressum
import './App.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Start from './pages/start';
import Event from './pages/event'
import { useEffect, useState } from 'react'
import { OrderJSONArrayAsc } from './Utils/OrderJSONArray'
import Navigation from './components/navigation';
import FAQ from './pages/faq';
import MietMich from './pages/mietmich';
import Kontakt from './pages/kontakt';
import Lage from './pages/lage';
import Footer from './components/footer';
import Impressum from './pages/impressum';

function App() {
  const [events, setEvents] = useState([])

  useEffect(() => {
    const fbtoken = 'EAAHZBaQLytkMBAKT9gyKuRnRFIcRwGhNBQ6GM7P092W3l7nMDwZAaauMkEQ34v2hjyTkHvguZASdZBd5bu0Tr4KeZB6BZBlJt5IozrmBC7GVeXhYWC5ZAAOgyamg72kGwIMUSftNVdG9M8AZB4ivnRpeUpY9yA4I2P0xaa3AfnqLrAZDZD';
    const fetchurl = 'https://graph.facebook.com/725866977454779/events?fields=cover,name,description,start_time,ticket_uri&time_filter=upcoming&access_token='+fbtoken;

    const fetchData = async () => {
      const respone = await fetch(fetchurl)
      const data = await respone.json()
      const dataSorted = OrderJSONArrayAsc(data.data,"start_time")
      setEvents(dataSorted)
    }

    fetchData()
  }, [])

  return (
    <div className="App" id="outer-container">
      <Router>
        <Routes>
          <Route exact path="/" element={<><Navigation /><Start events={events}/><Footer /></>} />
          <Route path="/event/:eventid" element={<><Navigation /><Event events={events} /><Footer /></>} />
          <Route exact path="/kontakt" element={<><Navigation /><Kontakt /><Footer /></>} />
          <Route path="/mietmich" element={<><Navigation /><MietMich /><Footer /></>} />
          <Route path="/faq" element={<><Navigation/><FAQ /><Footer /></>} />
          {/*<Route path="/lage" element={<><Navigation/><Lage /><Footer /></>} />*/}

          <Route path="/impressum" element={<><Navigation /><Impressum /></>} />
          <Route path="/datenschutz" element={<><Navigation /><h1>Datenschutz</h1></>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

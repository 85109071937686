import React from 'react'
import TitleDeco1 from '../../../img/titledeco1.png'
import './H1Styles.css'

function H1({children}) {
  return (
    <div className='page-title'>
        <img className='title-deco' src={TitleDeco1} />
        <h1>{children}</h1>
        <img className='title-deco title-deco--reversed' src={TitleDeco1} />
    </div>
  )
}   

export default H1
function GetSortOrder(prop,ascending) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return ascending ? 1 : -1;    
        } else if (a[prop] < b[prop]) {    
            return ascending ? -1 : 1;    
        }    
        return 0;    
    }    
}  

export const OrderJSONArray = (array,orderby) => {
    return array.sort(GetSortOrder(orderby,false))
}

export const OrderJSONArrayAsc = (array,orderby) => {
    return array.sort(GetSortOrder(orderby,true))
}
import React from 'react'
import H1 from '../../components/atoms/H1'
import './index.css'
import Fr1 from '../../img/fr1.png'
import Fr2 from '../../img/fr2.png'

function MietMich() {
    const rotation1 = Math.floor(Math.random() * 13) - 6
    const rotation2 = Math.floor(Math.random() * 13) - 6
  return (
    <div className='container'>
        <H1>Miet mich!</H1>
        <p className='mietmich-text'>
        Die Gisela folgt dem Konzept einer Homeparty mit allem, was man aus den eigenen vier Wänden kennt… <br />
Die Küche als Garderobe mit Sitzecke und dem abgetrennten Raucherbereich.
Das Schlafzimmer für Tanzpausen mit chillgem Bett und Fotobox für den Schnappschuss zwischendurch.<br /><br />
Das Wohnzimmer als großen Dancefloor mit großer Bar, Sitzgelegenheiten und Spielwerke.<br /><br />
Die Garage als zweiten Dancefloor mit eigener Bar und rustikaler Werkstattatmosphäre.<br /><br />
UND das Badezimmer als Badezimmer ;-]
        </p>
        <p className='mietmich-bold'>
          Jetzt hast du Bock auf deine eigene Party? Egal ob B-Day, Polterabend, Abschlussfeier oder einfach nur für zwischendurch, wir helfen dir!
          Mit Personal, Getränken, Musik und Co. Schreib uns an und wir kümmern uns 😉
        </p>

        <div className='image-gallery'>
            <img src={Fr1} style={{rotate: `${rotation1}deg`}} />
            <img src={Fr2} style={{rotate: `${rotation2}deg`}} />
        </div>
    </div>
  )
}

export default MietMich
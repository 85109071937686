import React from 'react'
import './H2Styles.css'
import TitleDeco2 from '../../../img/titledeco2.png'

function H2({onClick, children}) {
  return (
    <div className='title' onClick={onClick}>
        <img className='title-deco2' src={TitleDeco2} />
        <h2>{children}</h2>
        <img className='title-deco2 title-deco2--reversed' src={TitleDeco2} />
    </div>
  )
}

export default H2
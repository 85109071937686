import React, { useEffect, useState } from 'react';
import './start.css';
import H1 from '../../components/atoms/H1';
import H2 from '../../components/atoms/H2';
import { useNavigate } from 'react-router-dom';
import Logo from '../../img/logo.png';
import { slide as Menu } from 'react-burger-menu';
import Navigation from '../../components/navigation';

function Start({ events }) {
  const navigate = useNavigate();

  const renderEvents =
    events &&
    events.map((event) => {
      const rotation = Math.floor(Math.random() * 13) - 6;
      var date = new Date(event.start_time);
      return (
        <div className='event-wrapper'>
          <div className='rotator' style={{ rotate: `${rotation}deg` }}>
            <p
              className='event-starttime'
              onClick={() => navigate('/event/' + event.id)}
            >
              {date.toLocaleString('de-DE', {
                weekday: 'short',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}{' '}
              Uhr
            </p>
            <img
              onClick={() => navigate('/event/' + event.id)}
              class='cover mb-1 mt-1'
              alt='event cover'
              src={event.cover.source}
            />
            <H2 onClick={() => navigate('/event/' + event.id)}>{event.name}</H2>
          </div>
        </div>
      );
    });

  return (
    <>
      <H1>Das Partywohnzimmer in Dresden</H1>
      <br />

      {renderEvents}
    </>
  );
}

export default Start;

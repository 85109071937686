import React, {useState} from 'react'
import Logo from '../../img/logo.png'
import { useNavigate } from 'react-router-dom'
import './styles.css'
import H2 from '../atoms/H2'

function Navigation() {
    const navigate = useNavigate()
    const [leftOffset, setLeftOffset] = useState(100)

  return (
    <div className='header'>
    <div className='logo'  onClick={() => navigate("/")}>
      <img src={Logo} />
      <h1>Gisela.Club</h1>
    </div>
    <div className='nav'>
      <div className='burger-icon' onClick={() => setLeftOffset(0)}>
        <div /><div /><div />
      </div>
    </div>
    <div className='menu-wrapper' style= {{ left: `${leftOffset}%`}}>
        <H2>Menü</H2>
        <div className='link-list'>
            <a href='/'>Home</a>
            <a href='/mietmich'>Miet Mich!</a>
            <a href='/kontakt'>Kontakt</a>
            <a href='/faq'>FAQ</a>
            <a href='https://goo.gl/maps/8ygkN69fFtwmoK3f8' target='_blank'>Lage</a>
        </div>
        <div className='X' onClick={() => setLeftOffset(100)}>
        </div>
    </div>
  </div>
  )
}

export default Navigation